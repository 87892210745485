// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---app-src-pages-app-tsx": () => import("./../../../../app/src/pages/app.tsx" /* webpackChunkName: "component---app-src-pages-app-tsx" */),
  "component---app-src-pages-index-tsx": () => import("./../../../../app/src/pages/index.tsx" /* webpackChunkName: "component---app-src-pages-index-tsx" */),
  "component---node-modules-edekadigital-backoffice-theme-src-pages-404-index-tsx": () => import("./../../../../node_modules/@edekadigital/backoffice-theme/src/pages/404/index.tsx" /* webpackChunkName: "component---node-modules-edekadigital-backoffice-theme-src-pages-404-index-tsx" */),
  "component---node-modules-edekadigital-backoffice-theme-src-pages-index-tsx": () => import("./../../../../node_modules/@edekadigital/backoffice-theme/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-edekadigital-backoffice-theme-src-pages-index-tsx" */)
}

